import React, { lazy, Suspense } from 'react'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import AuthGuard from './components/AuthGuard/AuthGuard'
import Loading from './components/Loading/Loading'
import MasterPage from './components/MasterPage/MasterPage'
import { RoutePath } from './enums/Routes'

const Pages = {
  AUTH: lazy(() => import('./pages/Auth')),
  USER: lazy(() => import('./pages/User')),
  BANNERS: lazy(() => import('./pages/Banners')),
  NOMATCH: lazy(() => import('./pages/NoMatch')),
  ORDER: lazy(() => import('./pages/Order')),
  TICKET: lazy(() => import('./pages/Ticket')),
  MANUAL: lazy(() => import('./pages/Manual')),
  FAQ: lazy(() => import('./pages/Faq')),
  SMART_WATCH: lazy(()=> import('./pages/SmartWatch')),
  SHARED: lazy(()=> import('./pages/Shared')),
}

const App = (): JSX.Element => (
  <RecoilRoot>
    <Router>
      <Suspense fallback={<Loading />}>
        <AuthGuard>
          <MasterPage>
            <Switch>
              <Route path={RoutePath.AUTH} component={Pages.AUTH} />
              <Route exact path={RoutePath.HOME}>
                <Redirect to={RoutePath.USER} />
              </Route>
              <Route path={RoutePath.USER} component={Pages.USER} />
              <Route path={RoutePath.ORDER} component={Pages.ORDER} />
              <Route path={RoutePath.TICKET} component={Pages.TICKET} />
              <Route path={RoutePath.BANNERS} component={Pages.BANNERS} />
              <Route path={RoutePath.MANUAL} component={Pages.MANUAL} />
              <Route path={RoutePath.FAQ} component={Pages.FAQ} />
              <Route path={RoutePath.SMART_WATCH} component={Pages.SMART_WATCH}/>
              <Route path={RoutePath.SHARED} component={Pages.SHARED}/>
              <Route path="*" component={Pages.NOMATCH} />
           
            </Switch>
          </MasterPage>
        </AuthGuard>
      </Suspense>
    </Router>
  </RecoilRoot>
)

export default App
