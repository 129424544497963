import {
  AimOutlined,
  HistoryOutlined,
  OrderedListOutlined,
  QuestionCircleOutlined,
  SlidersOutlined,
  UserAddOutlined,
} from '@ant-design/icons'
import { Menu } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'
import { RoutePath } from '../../enums/Routes'
import { isNonAuthPage } from '../../utils/urls'

const navItems = () => [
  {
    index: 0,
    icon: <UserAddOutlined />,
    label: 'Kullanıcılar',
    path: RoutePath.USER,
  },
  {
    index: 1,
    icon: <OrderedListOutlined />,
    label: 'Siparişler',
    path: RoutePath.ORDER,
  },
  {
    index: 2,
    icon: <HistoryOutlined />,
    label: 'Servis Talepleri',
    path: RoutePath.TICKET,
  },
  {
    index: 3,
    icon: <QuestionCircleOutlined />,
    label: 'Kullanım Kılavuzları',
    path: RoutePath.MANUAL,
  },
  {
    index: 4,
    icon: <SlidersOutlined />,
    label: 'Bannerlar',
    path: RoutePath.BANNERS,
  },
  {
    index:5,
    icon: <SlidersOutlined />,
    label: 'Sıkça Sorulan Sorular',
    path: RoutePath.FAQ,
  },
  {
    index: 6,
    icon: <AimOutlined />,
    label: 'Akıllı Saatler',
    path: RoutePath.SMART_WATCH,
  },
  {
    index: 7,
    icon: <AimOutlined />,
    label: 'Sözleşmeler',
    path: RoutePath.SHARED,
  },
]

const Navigation = (): JSX.Element => {
  const history = useHistory()
  const local = useLocation()
  let item

  if (!isNonAuthPage(local.pathname)) {
    item = navItems().find(
      (item: any) => local.pathname.indexOf(item.path) !== -1
    )
  }

  return (
    <Menu defaultSelectedKeys={[`${item?.index}`]} mode="inline">
      {navItems().map((item: any, index: number) => {
        return item.children !== undefined ? (
          <Menu.SubMenu key={index} icon={item.icon} title={item.label}>
            {item.children.map((subItem: any) => {
              {
                console.log(subItem)
              }
              return (
                <Menu.Item
                  key={subItem.key}
                  icon={subItem.icon}
                  onClick={() => history.push(subItem.path)}
                >
                  {subItem.label}
                </Menu.Item>
              )
            })}
          </Menu.SubMenu>
        ) : (
          <Menu.Item
            key={index}
            icon={item.icon}
            onClick={() => history.push(item.path)}
          >
            {item.label}
          </Menu.Item>
        )
      })}
    </Menu>
  )
}

export default Navigation
