export enum RoutePath {
  HOME = '/',

  AUTH = '/auth',
  AUTH_LOGIN = '/auth/login',
  AUTH_LOGOUT = '/auth/logout',

  USER = '/user',
  USER_DETAIL = '/user/:id',
  USER_CREATE = '/user/create/:id',
  USER_EDIT = '/user/edit/:id',

  PREGNANCY = '/pregnancy',
  PREGNANCY_DETAIL = '/pregnancy/:id',
  PREGNANCY_CREATE = '/pregnancy/create/:id',
  PREGNANCY_EDIT = '/pregnancy/edit/:id',

  PREGNANCY_CATEGORY = '/pregnancy-category',
  PREGNANCY_CATEGORY_DETAIL = '/pregnancy-category/:id',
  PREGNANCY_CATEGORY_CREATE = '/pregnancy-category/create/:id',
  PREGNANCY_CATEGORY_EDIT = '/pregnancy-category/edit/:id',

  PREGNANCY_ARTICLE = '/pregnancy-article',
  PREGNANCY_ARTICLE_DETAIL = '/pregnancy-article/:id',
  PREGNANCY_ARTICLE_CREATE = '/pregnancy-article/create/:id',
  PREGNANCY_ARTICLE_EDIT = '/pregnancy-article/edit/:id',

  STORY = '/story',
  STORY_DETAIL = '/story/:id',
  STORY_CREATE = '/story/create/:id',
  STORY_EDIT = '/story/edit/:id',

  BANNERS = '/banners',
  BANNERS_CREATED = '/banners/create',
  BANNERS_EDIT = '/banners/edit/:id',
  BANNERS_DETAIL = '/banners/:id',

  ORDER = '/order',
  ORDER_DETAIL = '/order/:id',
  ORDER_CREATE = '/order/create/:id',
  ORDER_EDIT = '/order/edit/:id',

  TICKET = '/ticket/:orderNo',
  TICKET_DETAIL = '/ticket/detail/:id',
  TICKET_CREATE = '/ticket/create/:id',
  TICKET_EDIT = '/ticket/edit/:id',

  MANUAL = '/manual',
  MANUAL_DETAIL = '/manual/:id',
  MANUAL_CREATE = '/manual/create/:id',
  MANUAL_EDIT = '/manual/edit/:id',

  FAQ = '/faq',
  FAQ_DETAIL = '/faq/:id',
  FAQ_CREATE = '/faq/create/:id',
  FAQ_EDIT = '/faq/edit/:id',

  SHARED = '/shared',
  SHARED_DETAIL = '/shared/:id',
  SHARED_CREATE = '/shared/create/:id',
  SHARED_EDIT = '/shared/edit/:id',
  
  SMART_WATCH = '/smartwatch',
  SMART_WATCH_DETAIL = '/smartwatch/:id',
  SMART_WATCH_CREATE = '/smartwatch/create/:id',
  SMART_WATCH_EDIT = '/smartwatch/edit/:id',
}
